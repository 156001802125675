import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../scss/flip-card.scss"

class FlipCard extends React.Component {
    constructor(props) {
        super(props);
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.handleImageLoadError = this.handleImageLoadError.bind(this);
        this.handleClickEvent = this.handleClickEvent.bind(this);
        this.state = {
            show_loading: true,
            img_loaded: false,
            flip_card: false,
        };
        this.imageRef = React.createRef();
        this.spinnerRef = React.createRef();
    }

    componentDidMount() {
        if (!this.imageRef.current.complete) {
            this.imageRef.current.addEventListener("load", this.handleImageLoaded);
            this.imageRef.current.addEventListener("error", this.handleImageLoadError);
        } else {
            this.setState({
                show_loading: false,
                img_loaded: true,
            })
            this.imageRef.current.style.opacity = 1;
        }
    }

    handleImageLoaded() {
        this.setState({
            show_loading: false,
            img_loaded: true,
        })
        this.imageRef.current.style.opacity = 1;
    }

    handleImageLoadError() {
        console.log(`Unable to load image.`);
        this.setState({
            show_loading: false,
        })
    }

    handleClickEvent() {
        this.setState(state => ({ flip_card: !state.flip_card }));
    }

    render() {
        const { flip_card } = this.state;
        return (
            <div
                className="envelope"
                style={{
                    width: `${this.props.cardWidth}px`,
                    height: `${this.props.cardHeight}px`,
                }}
            >
                <button className={`flip-card${(flip_card) ? ' flip' : ''}`} onClick={this.handleClickEvent}>
                    <div className="flip-card__front">
                        {this.state.show_loading &&
                            <div className="flip-card-front__image__status">
                                <div className="flip-card-front__image__icon">
                                    <FontAwesomeIcon className="fa-icon fa-spin" icon={["far", "compass"]} />
                                </div>
                            </div>
                        }
                        <img
                            ref={this.imageRef}
                            src={this.props.frontImgSrc} 
                            alt={this.props.frontImgAlt}
                            className="flip-card-front__image"
                            onLoad={this.handleImageLoaded}
                            onError={this.handleImageLoadError}
                            style={{opacity: 0}}
                        />
                    </div>
                    <div className="flip-card__back">
                        <img
                            src={this.props.backImgSrc}
                            alt={this.props.backImgAlt}
                        />
                    </div>
                </button>
            </div>
        )
    }
}

export default FlipCard;