import React from "react"
import Layout from "../components/layout.js"
import FlipCard from "../components/flip-card.js"
import FrontImg from "../images/christmas-trees.jpg"
import BackImg from "../images/digital-card-back.svg"

const HolidayCard = () => {
    return(
        <Layout pageTitle="Holiday greetings">
            <div className="row">
                <div className="col-12 order-2 order-sm-1 mb-3">
                    <p>Click on the card to read the greeting:</p>
                </div>
                <div className="col-12 order-3 order-sm-2 mb-3">
                    <FlipCard
                        cardWidth="500"
                        cardHeight="650"
                        frontImgSrc={FrontImg}
                        frontImgAlt="Snow falling on pine trees"
                        backImgSrc={BackImg}
                        backImgAlt="Happy holidays, warmest wishes this holiday season"
                    />
                </div>
                <div className="col-12 order-1 order-sm-3 mb-3">
                    <p>
                        It's the holiday season, and that means a holiday-themed
                        project! We haven't done cards the last few years, (not 
                        since the last time I was excited about a card project.)
                        So, this year we made cards using the image below and a
                        custom insert with our holiday message.
                    </p>
                    <p>
                        The image is a winter watercolor scene, painted by me. The 
                        inside greeting was made using a <a href="https://jameslafuente.com/56th-street-free-font" title="56th Street (free font) by James Lafuente">
                        free font by James Lafuente, called "56th Street"</a> for the 
                        banner.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default HolidayCard